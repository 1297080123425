/*
 * @Description:
 * @Author: max
 * @Date: 2020-12-21 14:16:24
 * @LastEditTime: 2022-01-01 10:30:28
 * @LastEditors: max
 * @Reference:
 */
import requests from '@/utils/requests'
import qs from 'qs'
// 检测兑换码
export function cDh (data) {
  return requests({
    method: 'POST',
    url: '/api/ComApi/CheckCpw',
    data: qs.stringify(data)
  })
}

// 卡密兑换
export function duiHuan (data) {
  return requests({
    method: 'POST',
    url: '/api/ComApi/RechargeCpw',
    data: qs.stringify(data)
  })
}
