import axios from 'axios'
import JSONBig from 'json-bigint'
const requests = axios.create({
  // headers: {
  //   // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  //   'Content-Type': 'multipart/form-data'
  // },
  // 假如传来大数值id做处理
  transformResponse: [
    function (data, headers) {
      // transformResponse执行实际比响应拦截器更早,覆盖了axios默认处理
      return JSONBig.parse(data)
    }
  ]
})
export default requests
