<template>
  <!-- 充值页面 -->
  <div class="home-container">
    <!-- 头部宣传图 -->
    <!-- <div class="head">
      <img src="@/assets/title-pic.png" />
    </div> -->
    <!-- 兑换码区域 -->
    <div class="content-area">
      <!-- 兑换码标题 -->
      <div class="content-title">
        <p>请输入兑换码>进入充值页面</p>
      </div>

      <!-- 兑换码表单验证区域 -->

      <van-form ref="dhForm">
        <div class="content-input">
          <van-field
            v-model="dhValue"
            name="dhValue"
            placeholder="请输入兑换码"
            maxlength="32"
            :rules="duRules"
          />
        </div>
        <div class="content-btn" @click="showPopup($event)">
          <van-button type="info">充值</van-button>
        </div>
      </van-form>
    </div>

    <div class="attention-content">
      <p>注意事项：</p>
      <p>
        1.话费电子券支持中国移动、电信、联通三大运营商大陆地区通用充值（港澳台地区除外），自助兑换后系统将自动为您充值，请仔细核对充值手机号码，如因手机号码填写错误导致的损失由客户自行承担。
      </p>
      <p>2.请务必在话费电子券有效期内完成充值兑换，逾期自动失效概不补发。</p>

      <p>
        3.月初、月末等充值高峰期、运营商维护期间及携号转网号码兑换可能存在延迟，24-48小时内到账属正常情况，是否兑换成功请以运营商短信或账单明细为准。
      </p>
      <p>
        4.若充值后长时间未到账，或您在充值过程中遇到其他问题，可拨打供应商服务热线：4000085285
      </p>
    </div>
    <!-- 底部区域 start-->
    <div class="bottom">
      <span>服务提供商@叁陆</span>
    </div>
    <!-- 底部区域 end-->

    <!-- 弹出层 start -->
    <van-popup v-model="show">
      <div class="phone-show">
        <!-- <img src="@/assets/phone.jpg" alt="" /> -->
        <div class="tip">
          <span>充值{{ tip }}元</span>
        </div>
        <div class="p-input">
          <van-form ref="moblieForm">
            <div class="p-input">
              <van-field
                v-model="tel"
                name="tel"
                type="number"
                placeholder="请输入手机号"
                maxlength="11"
                :rules="telRules"
              >
              </van-field>
            </div>
            <van-button
              plain
              type="primary"
              size="small"
              @click="submit($event)"
              >提交</van-button
            >
          </van-form>
        </div>
      </div>
    </van-popup>
    <!-- 弹出层 end -->
  </div>
</template>

<script>
// import { Toast } from 'vant'
import { cDh, duiHuan } from '@/api/checkDh'
export default {
  name: 'pay-page',
  data () {
    return {
      // account: 'hfkuaiagenttest', // 测试用户ID
      account: 'cdyhhdzh', // 测试用户ID
      tel: '', // 充值手机号
      show: false, // 弹出层显示
      tip: '100', // 兑换码返回信息
      dhValue: '', // 兑换码
      duRules: [
        {
          required: true, // 必输项
          message: '兑换码不能为空' // 提示信息
        }
        // {
        //   pattern: /^[0-9a-zA-Z]{8}$/, // 正则验证
        //   message: '兑换码不正确,请重新输入'
        // }
      ],
      telRules: [
        // 手机号码验证规则
        {
          required: true, // 必输项
          message: '手机号不能为空' // 提示信息
        },
        {
          pattern: /^1[3-9]\d{9}$/, // 正则验证
          message: '手机号不存在,请重新输入'
        }
      ]
    }
  },

  watch: {
    // dhValue (val, oldval) {
    //   if (val.length === 8) {
    //     console.log(val)
    //     console.log(oldval)
    //     this.handleDhValueChange(val)
    //   }
    // }
  },
  methods: {
    handleDhValueChange () {},
    // 显示弹出层
    async showPopup (e) {
      try {
        await this.$refs.dhForm.validate('dhValue')
        const data = {
          account: this.account,
          pwd: this.dhValue
        }
        const { data: res } = await cDh(data)
        // console.log(res)
        // console.log(this.accout)
        // console.log(this.dhValue)
        if (res.Code !== '000') {
          return this.$toast.fail(res.Msg)
        }
        this.$toast.success(res.Msg)
        this.tip = res.Psize
      } catch (err) {
        return this.$toast.fail('兑换码错误')
      }
      this.show = true
    },
    async submit (e) {
      try {
        await this.$refs.moblieForm.validate('tel')
        console.log(this.tel)
        const data = {
          account: this.account,
          pwd: this.dhValue,
          mobile: this.tel
        }
        const { data: res } = await duiHuan(data)
        console.log(res)
        if (res.Code !== '000') {
          console.log(res)

          return this.$toast.fail(res.Msg)
        }
        this.$toast.success(res.Msg)
        this.show = false
        this.dhValue = ''
      } catch (err) {
        return this.$toast.fail('手机号错误')
      }
    }
  },
  computed: {}
}
</script>

<style scoped lang="less">
//顶部区域
// .head {
//   // 设置宽度与设备一致
//   width: 10rem;
//   img {
//     width: 100%;
//   }
// }
// 兑换码填写内容区域
.content-area {
  text-align: center;
  // background: url('~@/assets/center.png') no-repeat;
  // background-size: 100% 100%;
  margin: 0 0.666667rem;
  margin-top: 350px;
  // 标题样式
  .content-title {
    // width: 100%;
    // padding-top: 0.013333rem;
    p {
      margin-top: 50px;
      font-size: 0.373333rem;
      color: #515a6e;
    }
  }

  .content-input {
    margin: 60px 0.666667rem;
    // padding-top: 0.013333rem;
    .van-field {
      // margin-top: 0;
      border: 0.013333rem solid #ffffff;
      color: #666666;
      padding: 0.32rem 0.32rem;
      font-size: 0.373333rem;
      border-radius: 0.106667rem;
    }
  }
  .content-btn {
    margin-bottom: 50px;
    // padding-top: 0.013333rem;
    // margin-bottom: 100px;
    // width: 100%;
    // height: 0.266667rem;
    // img {
    //   margin: 80px 0;
    //   margin-bottom: 100px;
    //   width: 33%;
    // }
    .van-button {
      margin-top: 0;
      border-radius: 32px;
      border: 1px solid #ffe270;
      width: 7.4rem;
      height: 64px;
      background-color: #df6400;
    }
  }
}

//注意事项
.attention-content {
  margin: 0 1.333333rem;

  p {
    color: #999999;
    font-size: 0.34rem;
    margin: 0.2rem 0;
  }
}
// 底部信息
.bottom {
  text-align: center;
  // bottom: 0.066667rem;
  // margin: auto;
  width: 100%;
  span {
    font-size: 0.106667rem;
    color: #999999;
  }
}
// 弹出层样式
.phone-show {
  // width: 80%;
  background: url('~@/assets/phone.jpg') no-repeat;
  background-size: 100% 100%;
  width: 8.666667rem;
  height: 8.666667rem;
  // height: 700px;
  margin: 0 auto;
  text-align: center;
  // img {
  //   vertical-align: middle;
  //   margin: 0;
  //   padding: 0;
  //   border: 0;
  //   width: 100%;
  // }
  .tip {
    // top: 30%;
    // position: absolute;
    // margin: 0 auto;
    // left: 5%;
    // width: 90%;
    padding-top: 1.933333rem;
    color: #fc9b01;
    font-weight: 700;
    font-size: 0.573333rem;
  }
  .p-input {
    padding: 0.533333rem;
    // top: 40%;
    // position: absolute;
    // margin: 0 auto;
    // left: 7%;
    // width: 100%;
    .van-field {
      width: 6.666667rem;
      border: 1px solid #ffc740;
      // background: rgba(255, 255, 255, 0.4);
      color: #fa7878;
      // padding-left: 0.266667rem;
      display: table-cell;
      // padding: 0.16rem 0.32rem;
      font-size: 0.373333rem;
      line-height: 1.42857143;
      border-radius: 0.053333rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .van-button {
    margin-top: 0.533333rem;
    // position: absolute;
    // top: 40%;
    // left: 28%;
    // margin-left: --0.053333rem;
    // border-top-left-radius: 0;
    // border-bottom-left-radius: 0;
    border: 0.013333rem solid #fff;
    border-radius: 15px;
    // border-left: none;
    background: #ff9140;
    color: #fff;
    // display: inline-block;
    padding: 0.16rem 0.32rem;
    width: 200px;
    // margin-bottom: 0;
    font-size: 0.373333rem;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
}
</style>
